import {displayLoginButton, displayLogoutButton} from '../../modules/auth-buttons';
import {LogoutTracker} from '../../services/analytics/auth/logout';
import {
  applyRefreshToken,
  getAccessToken,
  getRefreshToken,
  isTokenValid,
  removeAuthorizationData} from '../../services/tokens';

export const customFetch = async ({
  domain = window.Site?.webApiDomain,
  url,
  headers = {},
  ...rest
}) => {
  if (!domain) {
    throw new Error('Website API: empty domain');
  }

  const response = await fetch(`${domain}${url}`, {
    headers: {'Content-Type': 'application/json', ...headers},
    ...rest
  });

  return response.json();
};

export const withAuthCheck = callback => {
  const isAccessTokenValid = isTokenValid(getAccessToken());

  if (isAccessTokenValid) {
    return callback();
  }

  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    LogoutTracker.systemAction();
    removeAuthorizationData();
    return Promise.resolve({
      tokenError: true
    });
  }

  return applyRefreshToken(
    () => {
      displayLogoutButton();
      return callback();
    },
    () => {
      displayLoginButton();
      return Promise.resolve({
        tokenError: true
      });
    },
    () => {
      LogoutTracker.systemAction();
      removeAuthorizationData();
      return Promise.resolve({
        tokenError: true
      });
    });
};
