import {GTAGPlugin} from '../../plugins/gtag';
import {ANALYTIC_EVENTS} from '../constants';
import EventSubscriber from '../event-subscriber';

window.gtagPlugin = window.gtagPlugin || new GTAGPlugin();
const track = window.gtagPlugin.trackEvent;

const gtagSubscriber = new EventSubscriber({
  [ANALYTIC_EVENTS.DIRECT_LINKS_CLICK]: ({position, direct_link_url}) => {
    track('event', 'direct_links_click', {position, direct_link_url});
  },
  [ANALYTIC_EVENTS.DIRECT_LINKS_IMPRESSION]: ({position, direct_link_url}) => {
    track('event', 'direct_links_impression', {position, direct_link_url});
  },
  [ANALYTIC_EVENTS.SHARE]: eventData => {
    const payload = eventData;

    if (eventData.article_id) {
      payload.article_url = eventData.url;
    }
    track('event', 'share_intent', payload);
  },
  [ANALYTIC_EVENTS.COMMERCIAL_MENU_IMPRESSION]: ({position}) => track('event', 'commercial_menu_impression', {
    position
  }),
  [ANALYTIC_EVENTS.COMMERCIAL_MENU_CLICK]: ({position}) => track('event', 'commercial_menu_click', {
    position
  }),
  [ANALYTIC_EVENTS.UFC_WIDGET_CLICK]: () => track('event', 'Widget Click (placeholder click)', {
    event_category: 'Live streaming widget CTR'
  }),
  [ANALYTIC_EVENTS.UFC_WIDGET_IMPRESSION]: () => track('event', 'Widget Impressions (user see 50% of widget)', {
    event_category: 'Live streaming widget CTR'
  }),
  [ANALYTIC_EVENTS.SET_USER_TYPE]: ({userType}) => track('set', {user_type: userType}),
  [ANALYTIC_EVENTS.PAGEVIEW]: () => track('event', 'page_view', {
    page_path: window.location.pathname
  }),
  [ANALYTIC_EVENTS.PAGEVIEW_INFINITE]: eventData => {
    if (eventData?.referer) {
      track('set', 'referrer', eventData.referer);
    }
    track('event', 'page_view', {
      page_path: window.location.pathname
    });
  },
  [ANALYTIC_EVENTS.ARTICLE_SCROLL]: ({contentTypeIndex}) => track('event', 'counter_scrolled_article', {
    scroll_depth: `${contentTypeIndex}`
  }),
  [ANALYTIC_EVENTS.ONE_SIGNAL_EMBED_EMBED_CLICK]: () => track('event', 'OneSignal_embed_click'),
  [ANALYTIC_EVENTS.ONE_SIGNAL_EMBED_EMBED_IMPRESSION]: () => track('event', 'OneSignal_embed_impression')
});

export {gtagSubscriber};
