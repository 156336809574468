const USER_ROLES = {
  EXTENDED: 'extended', // has phone number
  REGULAR: 'regular'
};

const USER_ACTION_STATES = {
  AUTHORIZED: 'authorized',
  REGISTERED: 'registred'
};

export {USER_ROLES, USER_ACTION_STATES};
