import {initGrowthBook} from './growthbook/init';
import {handleLoginEvent} from './modules/auth-buttons';

const loadGrowthBook = async () => {
  window.growthBook = window.growthBook || await initGrowthBook();
};

loadGrowthBook();

handleLoginEvent(() => {
  window.growthBook = null;
  loadGrowthBook();
});
