import {LOGOUT_EVENT_TARGETS} from '../../../../constants/event-targets/logout';
import {JITSU_EVENT_TYPES} from '../../../../constants/event-types/jitsu';
import {getArticleDetails} from '../../article-details';
import {createPageviewPayload} from '../../jitsu-helpers';
import {JitsuPlugin} from '../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../constants';
import EventSubscriber from '../event-subscriber';

import {adfoxSubscriberEvents} from './events/adfox';
import {mobileBottomNavigationSubscriberEvents} from './events/mobile-bottom-navigation';
import {requestErrorSubscriberEvents} from './events/request-error';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const jitsuSubscriber = new EventSubscriber({
  [ANALYTIC_EVENTS.PAGEVIEW]: window.jitsuPlugin.trackPageview,
  [ANALYTIC_EVENTS.RESIZE]: () => track(JITSU_EVENT_TYPES.PAGE_RESIZE),
  [ANALYTIC_EVENTS.SUBSCRIPTION_CHANGE]: ({isSubscribed}) => track(
    isSubscribed ? JITSU_EVENT_TYPES.USER_SUBSCRIBED : JITSU_EVENT_TYPES.USER_UNSUBSCRIBED, {
      event_date: new Date().toJSON()
    }),
  [ANALYTIC_EVENTS.LOGIN]: eventData => track(JITSU_EVENT_TYPES.USER_LOGIN, eventData),
  [ANALYTIC_EVENTS.REGISTRATION]: eventData => track(JITSU_EVENT_TYPES.USER_REGISTRATION, eventData),
  [ANALYTIC_EVENTS.RECOVER]: eventData => track(JITSU_EVENT_TYPES.USER_RECOVERY, eventData),
  [ANALYTIC_EVENTS.COMMENTS]: eventData => track(JITSU_EVENT_TYPES.USER_COMMENTS, eventData),
  [ANALYTIC_EVENTS.LOGOUT_BY_USER]: () => track(JITSU_EVENT_TYPES.USER_LOGOUT, {
    target: LOGOUT_EVENT_TARGETS.USER
  }),
  [ANALYTIC_EVENTS.LOGOUT_BY_SYSTEM]: () => track(JITSU_EVENT_TYPES.USER_LOGOUT, {
    target: LOGOUT_EVENT_TARGETS.SYSTEM
  }),
  [ANALYTIC_EVENTS.REFRESH_TOKEN_SUCCESS]: () => track(JITSU_EVENT_TYPES.USER_REFRESH_TOKEN, {
    result: 'success'
  }),
  [ANALYTIC_EVENTS.REFRESH_TOKEN_ERROR]: ({errorMessage}) => track(JITSU_EVENT_TYPES.USER_REFRESH_TOKEN, {
    result: 'error',
    error_message: errorMessage
  }),
  [ANALYTIC_EVENTS.SHARE]: eventData => track(JITSU_EVENT_TYPES.SHARE, eventData),
  [ANALYTIC_EVENTS.PAGEVIEW_INFINITE]: eventData => {
    if (eventData.article_id) {
      const preparedPayload = {
        ...getArticleDetails(eventData.article_id),
        page: 'Article',
        authorIds: eventData.article_authors_ids || [],
        contentType: eventData.targeting_attributes?.content_type || '',
        referer: eventData.referer || ''
      };

      if (eventData.article_parent_category_slug) {
        preparedPayload.category = eventData.article_parent_category_slug;
        preparedPayload.subcategory = eventData.article_categories_slugs || [];
      } else {
        preparedPayload.category = eventData.article_categories_slugs || [];
      }
      track(JITSU_EVENT_TYPES.PAGEVIEW, createPageviewPayload(preparedPayload));
    }
  },
  [ANALYTIC_EVENTS.RECOMMENDATION]: ({eventType, articleId}) => track(eventType, getArticleDetails(articleId)),
  [ANALYTIC_EVENTS.SHARE_BUTTON_CLICK]: ({layoutSection}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'shares_summary',
    event_target_component: 'article_action_menu',
    event_target_layout_section: layoutSection,
    feature: 'article_shares'
  }),
  [ANALYTIC_EVENTS.SHARE_WINDOW_CLOSE_BY_ICON]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'close_button',
    event_target_component: 'shares_catalog',
    event_target_layout_section: 'modal_window',
    feature: 'article_shares'
  }),
  [ANALYTIC_EVENTS.SHARE_WINDOW_CLOSE_BY_OVERLAY]: () => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'overlay',
    feature: 'article_shares'
  }),
  [ANALYTIC_EVENTS.SHARE_SOCIAL]: ({socialName}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'shares_catalog_item',
    event_target_alt_text: socialName,
    event_target_component: 'shares_catalog',
    event_target_layout_section: 'modal_window',
    feature: 'article_shares'
  }),
  [ANALYTIC_EVENTS.SHARE_ARTICLE_BUTTON_CLICK]: ({layoutSection, text}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'shares_summary',
    event_target_component: 'shares_summary',
    event_target_layout_section: layoutSection,
    feature: 'article_shares',
    event_target_text_content: text
  }),
  [ANALYTIC_EVENTS.GROWTHBOOK_INIT]: eventData => track(JITSU_EVENT_TYPES.GROWTHBOOK_INIT, {
    grb: eventData
  }),
  [ANALYTIC_EVENTS.ARTICLE_COMMENTS_OBSERVE]: data => track(JITSU_EVENT_TYPES.OBSERVE, {
    ...data,
    event_target: 'comments_feed',
    event_target_component: 'comments_feed',
    event_target_layout_section: 'right_drawer',
    feature: 'article_comments'
  }),
  [ANALYTIC_EVENTS.SHARE_TREND]: ({text}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'shares_summary',
    event_target_text_content: text,
    event_target_component: 'shares_summary',
    event_target_layout_section: 'bottom_navigation_bar',
    feature: 'article_shares'
  }),
  [ANALYTIC_EVENTS.LANGUAGE_SWITCHER_CLICK_EVENT]: ({language}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: 'language_switcher_button',
    event_target_value: language,
    event_target_component: 'language_switcher_button',
    event_target_layout_section: 'main_content_header',
    feature: 'article'
  }),
  ...requestErrorSubscriberEvents,
  ...adfoxSubscriberEvents,
  ...mobileBottomNavigationSubscriberEvents
});

export {jitsuSubscriber};
