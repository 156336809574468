import {getDeviceId} from '../../services/device-id-helpers';
import {getAccessToken, getTokenPayload, isTokenValid} from '../../services/tokens';
import {getUnauthenticatedUserId} from '../../services/user-id-helpers';
import {customFetch} from '../utils/fetch';
import {store} from '../utils/shared/store';

const TOKEN_USER_KEYS = ['id', 'provider', 'role', 'username'];
const mapTokenUserKeys = tokenUser => TOKEN_USER_KEYS.reduce((result, currentKey) => ({
  ...result,
  [currentKey]: tokenUser[currentKey]
}), {});

function getInitialUser () {
  const accessToken = getAccessToken();

  if (isTokenValid(accessToken)) {
    return {
      ...mapTokenUserKeys(getTokenPayload(accessToken)),
      isAuthenticated: true,
      deviceId: getDeviceId()
    };
  }
  return {
    isAuthenticated: false,
    deviceId: getDeviceId(),
    id: getUnauthenticatedUserId()
  };
}

class UserMemoryRepository {
  constructor() {
    if (!UserMemoryRepository.instance) {
      UserMemoryRepository.instance = this;
    }
    this.store = store({user: getInitialUser()});
    this.subscribe = this.store.subscribe;

    return UserMemoryRepository.instance;
  }

  get() {
    return this.store.user;
  }

  set(user) {
    this.store.user = user;
  }

  clear() {
    this.store.user = {
      isAuthenticated: false,
      deviceId: getDeviceId(),
      id: getUnauthenticatedUserId()
    };
  }

  async update () {
    try {
      const fetchedUser = await customFetch({
        url: 'users/profile',
        domain: window.Site?.authDomain,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        }
      });

      this.store.user = {
        ...this.store.user,
        ...getInitialUser(),
        ...fetchedUser,
        isAuthenticated: true,
        isFetched: true
      };

      return this.store.user;
    } catch (error) {
      console.error(error);
    }
  }
}

function initUserMemoryRepository () {
  window.userMemoryRepository = window.userMemoryRepository || new UserMemoryRepository();
}

function getUser () {
  return window.userMemoryRepository && window.userMemoryRepository.get() || getInitialUser();
}

export {initUserMemoryRepository, getUser};
