import {JITSU_EVENT_TYPES} from '../../../../../constants/event-types/jitsu';
import {JitsuPlugin} from '../../../plugins/jitsu';
import {ANALYTIC_EVENTS} from '../../constants';

window.jitsuPlugin = window.jitsuPlugin || new JitsuPlugin();
const track = window.jitsuPlugin.trackEvent;

const mobileBottomNavigationSubscriberEvents = {
  [ANALYTIC_EVENTS.MOBILE_BOTTOM_NAVIGATION_TAP]: ({eventTarget}) => track(JITSU_EVENT_TYPES.TAP, {
    event_target: eventTarget,
    event_target_value: 'add',
    event_target_component: 'navigation_menu',
    event_target_layout_section: 'bottom_navigation_bar',
    feature: 'navigation'
  })
};

export {mobileBottomNavigationSubscriberEvents};
