import {GrowthBook} from '@growthbook/growthbook';

import {getUser} from '../website-ui/repositories/user-memory-repository';

import {GROWTHBOOK_EVENTS, PROD_DOMAIN} from './constants';

export async function initGrowthBook() {
  const user = getUser();

  const grb = new GrowthBook({
    enableDevMode: window.Site.domain !== PROD_DOMAIN,
    backgroundSync: false,
    ...window.Site.growthBookFeatures,
    attributes: {
      user_id: user.id,
      user_device_id: user.deviceId,
      is_authenticated: user.isAuthenticated,
      url: window.location.href
    }
  });

  const growthBookEvent = new CustomEvent(GROWTHBOOK_EVENTS.LOAD, {
    detail: {growthBook: grb}
  });

  document.dispatchEvent(growthBookEvent);

  return grb;
}
