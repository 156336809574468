import {PROFILE_PROVIDERS} from '../../website-ui/components/profile/profile-information/constants';
import {USER_ROLES} from '../../website-ui/constants/user';
import {getUser} from '../../website-ui/repositories/user-memory-repository';

export function addJitsuUserProperty (property, value) {
  const jitsu = window.jitsu;

  if (jitsu && jitsu.initialized) {
    jitsu.id({
      [property]: value
    }, true);
  }
}

export function addJitsuProperty (property, value) {
  const jitsu = window.jitsu;

  if (jitsu && jitsu.initialized) {
    jitsu.set({
      [property]: value
    });
  }
}

export function removeJitsuProperty (property) {
  const jitsu = window.jitsu;

  if (jitsu && jitsu.initialized) {
    jitsu.unset(property);
  }
}

export function createPageviewPayload ({
  articleId,
  page,
  category = [],
  subcategory = [],
  authorIds = [],
  contentType = '',
  referer
}) {
  const user = getUser();

  const commonPayload = {
    is_logged: user.isAuthenticated
  };

  if (referer) {
    commonPayload.referer = referer;
  }

  const extendedPayload = {
    ...commonPayload,
    page_category: category.join()
  };

  if (subcategory.length) {
    extendedPayload.page_subcategory = subcategory.join();
  }

  switch (page) {
    case 'Article':
      return {
        ...extendedPayload,
        article_id: articleId ? Number(articleId) : '',
        article_authors_ids: authorIds.join(),
        article_attr_content_type: contentType
      };
    case 'Rubric':
      return extendedPayload;
    default:
      return commonPayload;
  }
}

export const refreshUserProperties = user => {
  addJitsuProperty('is_authenticated', user.isAuthenticated);
  addJitsuProperty('registration_source', user.provider || 'No data provided');
  addJitsuProperty('user_has_phone_linked', user.role === USER_ROLES.EXTENDED);
  addJitsuProperty('user_has_nickname', Boolean(user.username));
  addJitsuUserProperty('account_id', user.id);
  addJitsuProperty('user_has_google_linked', user.provider === PROFILE_PROVIDERS.GOOGLE || Boolean(user?.email));
  addJitsuProperty('user_has_apple_linked', user.provider === PROFILE_PROVIDERS.APPLE || Boolean(user?.apple?.id));
};

export const cleanUserProperties = () => {
  addJitsuProperty('is_authenticated', false);
  removeJitsuProperty('registration_source');
  removeJitsuProperty('user_has_google_linked');
  removeJitsuProperty('user_has_apple_linked');
  removeJitsuProperty('user_has_phone_linked');
  removeJitsuProperty('user_has_nickname');
};
