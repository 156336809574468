import queryString from 'query-string';

import {JWT} from '../golos/constants';
import {LogoutTracker} from '../services/analytics/auth/logout';
import {refreshUserProperties} from '../services/analytics/jitsu-helpers';
import {
  applyRefreshToken,
  removeAuthorizationData
} from '../services/tokens';
import {setAuthorizationDataAndUserId} from '../services/user-id-helpers';
import {MOBILE_ACTIONS, redirectToMobileLogout} from '../website-ui/pages/mobile/constants';
import {getUser} from '../website-ui/repositories/user-memory-repository';

import {createLinkToLogin} from './auth-pages/constants';

const AUTH_EVENT = 'nur_auth';
const AUTH_LOGIN_MESSAGE = 'success_login';
const AUTH_LOGOUT_MESSAGE = 'success_logout';

const $iconLogged = document.querySelector('.js-icon-logged');
const $iconUnlogged = document.querySelector('.js-icon-unlogged');
const $authTextBtn = document.querySelector('.js-auth-btn-text');

const displayLogoutButton = () => {
  if ($authTextBtn && $iconUnlogged && $iconLogged) {
    $authTextBtn.textContent = $authTextBtn?.dataset?.loggedText;
    $authTextBtn.classList.add('nonVisibleOnMobile');
    $iconUnlogged.classList.add('nonvisible');
    $iconUnlogged.classList.remove('nonVisibleOnMobile');
    $iconUnlogged.classList.remove('visible');
    $iconLogged.classList.add('visible');
    $iconLogged.classList.remove('nonvisible');
    $iconLogged.style.display = '';
    $iconUnlogged.style.display = '';
  }
};

const displayLoginButton = () => {
  if ($authTextBtn && $iconUnlogged && $iconLogged) {
    $authTextBtn.textContent = $authTextBtn?.dataset?.unloggedText;
    $authTextBtn.classList.add('visible');
    $authTextBtn.classList.add('nonVisibleOnMobile');

    $iconUnlogged.classList.add('visible');
    $iconUnlogged.classList.remove('nonvisible');
    $iconLogged.classList.add('nonvisible');
    $iconLogged.classList.remove('visible');
    $iconLogged.style.display = '';
    $iconUnlogged.style.display = '';
  }
};

const authTriggerEvent = data => {
  window.dispatchEvent(new CustomEvent(AUTH_EVENT, data));
};

const handleLoginEvent = callback => {
  window.addEventListener(AUTH_EVENT, event => {
    if (event.detail.message === AUTH_LOGIN_MESSAGE) {
      callback(event.detail.user);
    }
  });
};

const unsubscribeFromLoginEvent = callback => {
  window.removeEventListener(AUTH_EVENT, callback);
};

const handleLogoutRedirect = () => {
  const parsedSearch = queryString.parse(decodeURIComponent(window.location.search)) || {};
  const mobilePlatform = parsedSearch.mobilePlatform || '';
  const mobileAction = parsedSearch.mobileAction || '';

  if (mobileAction === MOBILE_ACTIONS.WATCH_LOGOUT) {
    redirectToMobileLogout(mobilePlatform);
  } else {
    window.location.href = '/';
  }
};

const handleLogout = (withRedirect = true) => {
  removeAuthorizationData();
  window.userMemoryRepository.clear();
  displayLoginButton();

  window.CoralEmbed?.logout();
  authTriggerEvent({
    detail: {
      message: AUTH_LOGOUT_MESSAGE
    }
  });
  LogoutTracker.userAction();
  if (withRedirect) {
    handleLogoutRedirect();
  }
  localStorage.removeItem(JWT);
};

const handleLoginRedirect = event => {
  event.target.disabled = true;
  const query = window.location.pathname !== '/' ? {
    redirectURL: window.location.pathname
  } : {};

  window.location.href = createLinkToLogin(query);
};

const handleLoginSuccess = async response => {
  setAuthorizationDataAndUserId(response);
  const user = await window.userMemoryRepository.update();

  refreshUserProperties(user);
  authTriggerEvent({
    detail: {
      message: AUTH_LOGIN_MESSAGE,
      user
    }
  });
};

const initListenerAuthButton = () => {
  const $authBtn = document.querySelector('.js-auth-btn');

  $authBtn?.addEventListener('click', event => {
    const user = getUser();

    if (!user.isAuthenticated) {
      applyRefreshToken(
        () => {
          window.location.href = '/profile';
        },
        displayLoginButton,
        () => {
          LogoutTracker.systemAction();
          removeAuthorizationData();
          handleLoginRedirect(event);
        }
      );
      handleLoginRedirect(event);
    }

    if (user.isAuthenticated) {
      window.location.href = '/profile';
      return null;
    }
  });
};

const initAuthButton = () => {
  const user = getUser();

  if (user.isAuthenticated) {
    displayLogoutButton();
  } else {
    displayLoginButton();
  }
  initListenerAuthButton();
  handleLoginEvent(displayLogoutButton);
};

export {
  AUTH_EVENT,
  AUTH_LOGIN_MESSAGE,
  AUTH_LOGOUT_MESSAGE,
  displayLogoutButton,
  displayLoginButton,
  authTriggerEvent,
  handleLoginEvent,
  unsubscribeFromLoginEvent,
  handleLogout,
  handleLoginSuccess,
  initAuthButton
};
