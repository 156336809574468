import {ADFOX_EVENTS} from './events/adfox';
import {DIRECT_LINKS_EVENTS} from './events/direct-links';
import {MOBILE_BOTTOM_NAVIGATION} from './events/mobile-bottom-navigation';
import {ONE_SIGNAL_EMBED} from './events/one-signal-embed';
import {REQUEST_ERROR_EVENTS} from './events/request-error';

const ANALYTIC_EVENTS = {
  PAGEVIEW: 'pageview',
  RESIZE: 'resize',
  PAGEVIEW_INFINITE: 'pageview_infinite',
  COMMERCIAL_MENU_IMPRESSION: 'commercial_menu_impression',
  COMMERCIAL_MENU_CLICK: 'commercial_menu_click',
  SUBSCRIPTION_CHANGE: 'subscription_change',
  LOGIN: 'login',
  REGISTRATION: 'registration',
  RECOVER: 'recover',
  SET_USER_TYPE: 'set_user_type',
  COMMENTS: 'comments',
  LOGOUT_BY_USER: 'logout_by_user',
  LOGOUT_BY_SYSTEM: 'logout_by_system',
  REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
  REFRESH_TOKEN_ERROR: 'refresh_token_error',
  SHARE: 'share',
  RECOMMENDATION: 'recommendation',
  UFC_WIDGET_CLICK: 'ufc_widget_click',
  UFC_WIDGET_IMPRESSION: 'ufc_widget_impression',
  PM_WIDGET_CLICK: 'pm_widget_click',
  PM_WIDGET_IMPRESSION: 'pm_widget_impression',
  ONEXBET_WIDGET_CLICK: 'onexbet_widget_click',
  ONEXBET_WIDGET_IMPRESSION: 'onexbet_widget_impression',
  ARTICLE_SCROLL: 'article_scroll',
  VISIT: 'visit',
  SHARE_BUTTON_CLICK: 'share_button_click',
  SHARE_WINDOW_CLOSE_BY_ICON: 'share_window_close_by_icon',
  SHARE_WINDOW_CLOSE_BY_OVERLAY: 'share_window_close_by_overlay',
  SHARE_SOCIAL: 'share_social',
  SHARE_ARTICLE_BUTTON_CLICK: 'share_article_button_click',
  GROWTHBOOK_INIT: 'growthbook_init',
  ARTICLE_COMMENTS_OBSERVE: 'article_comments_observe',
  SHARE_TREND: 'share_trend',
  LANGUAGE_SWITCHER_CLICK_EVENT: 'LANGUAGE_SWITCHER_CLICK_EVENT',
  ...REQUEST_ERROR_EVENTS,
  ...ONE_SIGNAL_EMBED,
  ...DIRECT_LINKS_EVENTS,
  ...ADFOX_EVENTS,
  ...MOBILE_BOTTOM_NAVIGATION
};

const NOTIFICATION_EVENTS = {
  SHOW: 'show-notification',
  HIDE: 'hide-notification'
};

export {
  ANALYTIC_EVENTS,
  NOTIFICATION_EVENTS
};
