import {RefreshTokenTracker} from './analytics/auth/refresh-token';
import {cleanUserProperties} from './analytics/jitsu-helpers';
import {deleteCookie, getCookie, setCookie} from './cookie';
import hiveSDK from './hive-sdk';

const ACCESS_TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const getAccessToken = () => getCookie(ACCESS_TOKEN_KEY);

const getRefreshToken = () => getCookie(REFRESH_TOKEN_KEY);

const getTokenPayload = token => {
  if (!token) {
    return {};
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => `%${ (`00${ c.charCodeAt(0).toString(16)}`).slice(-2)}`)
        .join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
};

const isTokenValid = token => {
  if (!token) {
    return false;
  }

  const {exp} = getTokenPayload(token);

  if (!exp) {
    return false;
  }

  return new Date(exp * 1000).getTime() >= new Date().getTime();
};

const setAuthorizationData = ({access_token, refresh_token}) => {
  if (!access_token || !refresh_token) {
    return;
  }

  const currentSecondsTimeStamp = Math.round(new Date().getTime() / 1000);

  const accessTokenPayload = getTokenPayload(access_token);
  const refreshTokenPayload = getTokenPayload(refresh_token);

  const accessTokenLiveTimeInHours = Math.ceil((accessTokenPayload.exp - currentSecondsTimeStamp) / 60 / 60);
  const refreshTokenLiveTimeInHours = Math.ceil((refreshTokenPayload.exp - currentSecondsTimeStamp) / 60 / 60);

  setCookie(ACCESS_TOKEN_KEY, access_token, accessTokenLiveTimeInHours);
  setCookie(REFRESH_TOKEN_KEY, refresh_token, refreshTokenLiveTimeInHours);
};

export const removeAuthorizationData = () => {
  deleteCookie(ACCESS_TOKEN_KEY);
  deleteCookie(REFRESH_TOKEN_KEY);
};

export const applyRefreshToken = (
  onSuccess = () => null,
  onError = () => null,
  onErrorTokenExist = () => null
) => {
  const refreshToken = getRefreshToken();

  if (refreshToken) {
    const accessToken = getAccessToken();

    return hiveSDK.refresh({refresh_token: refreshToken}, response => {
      setAuthorizationData(response);
      RefreshTokenTracker.onSuccess();
      return onSuccess(response);
    },
    error => {
      cleanUserProperties();
      RefreshTokenTracker.onError(error?.message || '');
      onError();
      return onErrorTokenExist();
    },
    accessToken
    );
  }
  return onError();
};

export {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  getAccessToken,
  getRefreshToken,
  getTokenPayload,
  isTokenValid,
  setAuthorizationData
};
