import {v4 as uuidv4} from 'uuid';

import {getUser} from '../website-ui/repositories/user-memory-repository';

import {getCookie, setCookie} from './cookie';
import {setAuthorizationData} from './tokens';

const USER_ID_STORAGE_KEY = 'nur_user_id';
const EXPIRES_HOURS = 10000;

const setStorageUserId = hash => {
  setCookie(USER_ID_STORAGE_KEY, hash, EXPIRES_HOURS);
};

const getStorageUserId = () => (
  getCookie(USER_ID_STORAGE_KEY) || null
);

const setAuthorizationDataAndUserId = data => {
  if (data.id) {
    setStorageUserId(data.id);
  }

  setAuthorizationData(data);
};

const getUnauthenticatedUserId = () => {
  const userId = getStorageUserId();

  if (!userId) {
    const newUserId = uuidv4();

    setStorageUserId(newUserId);

    return newUserId;
  }

  return userId;
};

const getUserId = () => {
  const user = getUser();

  return user?.id;
};

export {
  getUserId,
  getUnauthenticatedUserId,
  setAuthorizationDataAndUserId,
  getStorageUserId
};
