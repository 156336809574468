const FIELD_NAMES = {
  NICKNAME: 'nickname',
  PHONE: 'phone',
  PHONE_CODE: 'code',
  BIRTH_DATE: 'birthdate',
  PASSWORD: 'password',
  GENDER: 'gender'
};

const PROFILE_PROVIDERS = {
  GOOGLE: 'google',
  APPLE: 'apple',
  PHONE: 'phone'
};

export {FIELD_NAMES, PROFILE_PROVIDERS};
